import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { i18n } from 'bundles/utils';

import { locale } from './utils';
import { DatePickerInstance } from './types';
import { WithFormControlProps } from 'bundles/hocs/withFormControl/types';
import isAfter from "date-fns/isAfter";

import './styles.scss';

registerLocale('locale', locale);

const DatePickerComponent = forwardRef((props: WithFormControlProps, ref: any) => {
  const { placeholder = i18n.t('labels.date_format'), value, dateFormat: df = 'yyyy-MM-dd', monthsShown = 2, ...rest } = props;

  return (
    <DatePicker
      placeholderText={placeholder}
      locale="locale"
      dateFormat={df}
      autoComplete="off"
      disabledKeyboardNavigation
      monthsShown={monthsShown}
      onChangeRaw={(e: any) => {
        if(e.target.value?.length < 10 || String(new Date(e.target.value)) === 'Invalid Date') return;
        
        isAfter(new Date(e.target?.value), new Date(rest.maxDate)) ? rest.onChange(new Date(rest.maxDate), e): rest.onChange(e.target.value, e);
      }}
      selected={value ? new Date(value) : null}
      {...rest}
      ref={(instance: DatePickerInstance) => {
        (instance && ref(instance.input));
      }}
    />
  );
});

DatePickerComponent.displayName = 'Datepicker';

export default DatePickerComponent;
